<template>
  <div class="hotGoods">
    <section class="Title" v-if="hotProducts && hotProducts.length > 0">
      <p class="TitleBorder"></p>
      <p class="TitleName">{{ $t.cStore.hotSale }}</p>
    </section>
    <van-row class="list">
      <van-col
        class="item"
        v-for="item in hotProducts"
        :key="item.ProductNumber"
        @click="getProductDetails(item.ProductNumber, item.ShopId)"
      >
        <div class="discount" v-if="item.IsCouponProduct">
          {{ $t.discount }}
        </div>
        <van-image
          class="goodsImg"
          :src="
            item.ProductPhoto && imgUrlFilter(item.ProductPhoto.split('|')[0])
          "
        >
          <template v-slot:error>
            <img class="ImgIcon" src="~assets/img/other/empty.gif" />
          </template>
        </van-image>

        <div class="itemDesc">
          <div class="name">{{ item.ProductName }}</div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
export default {
  name: "HotGoods",
  props: ["hotProducts"],
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    // 跳转详情页
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.hotGoods {
  width: 100%;
  padding: 12px 0;
  .Title {
    @include publicFlex;
    .TitleBorder {
      margin: 0;
      padding: 0;
      height: 5px;
      width: 15px;
      border-radius: 10px;
      background-color: #43cd9a;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
    .TitleName {
      margin: 0;
      padding: 0;
      font-size: 15px;
      color: #000000;
    }
  }
  .list {
    width: 100%;
    .item {
      position: relative;
      width: 31%;
      margin: 0;
      margin-top: 10px;
      background-color: #ffffff;
      @include publicBoxRadio;
      &:nth-child(3n-1) {
        margin: 10px 3.5%;
        margin-bottom: 0;
      }
      .discount {
        position: absolute;
        z-index: 11;
        font-size: 12px;
        display: inline-block;
        background: red;
        color: white;
        padding: 0px 2px;
        border-radius: 2px;
      }
      .goodsImg {
        height: 28.5vw;
        width: 100%;
        border-top-left-radius: 8.5px;
        border-top-right-radius: 8.5px;
        object-fit: cover;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        margin: 0;
        .ImgIcon {
          height: 100%;
          width: 100%;
          border-top-left-radius: 8.5px;
          border-top-right-radius: 8.5px;
          object-fit: cover;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
        }
        ::v-deep .van-image__img {
          height: 100%;
          width: 100%;
          border-top-left-radius: 8.5px;
          border-top-right-radius: 8.5px;
          object-fit: cover;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
        }
        ::v-deep .van-image__error {
          height: 100%;
          width: 100%;
          border-top-left-radius: 8.5px;
          border-top-right-radius: 8.5px;
          object-fit: cover;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
        }
      }
      .itemDesc {
        text-align: center;
        overflow: hidden;
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 10px;
        padding-top: 5px;
        font-size: 12px;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .name {
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .itemSales {
        margin: 0;
        padding: 0;
        padding: 0 9px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        font-size: 9px;
        padding-bottom: 9px;
        color: #a7a7a7;
      }
    }
  }
}
</style>
